/*
 * @Author: 王广婷
 * @Date: 2019-10-31 09:24:19
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-05-27 18:03:46
 */

import axios from "@/lib/api.request";

// 新建家庭
export const addFamliy = data => axios.post("/abd/family/addfamily", data);

// 查询家庭列表
export const famliyList = data => axios.post("/abd/family/list", data);

// 添加家庭成员
export const addFamliyPerson = data =>
  axios.post("/abd/family/addfamilymembers", data);

// 删除家庭被保人
export const deleteInsured = data => axios.post("/abd/family/delfamily", data);

// 添加家庭成员手动选择
export const usersearch = data =>
  axios.post("/abd/muser/userinsuredList", data);

// 生成报告
export const examine = data =>
  axios.post("/abd/family/selectFamilyReport", data);

// 查看报告/abd/family/selectFamilyReportList
export const guarantee = data =>
  axios.post("/abd/family/selectFamilyReportList", data);

// 上传保单图片
export const addpolicypic = data =>
  axios.post("/abd/abtComAbdPolicyImg/add", data);

// 家庭详情
export const getfamilydetails = data =>
  axios.post("/abd/family/showFamilyBase", data);

// 家庭成员信息带出
export const getfamilymember = data =>
  axios.post("/abd/family/selectfamilymembers", data);

// 家庭保单分析获取reportno
export const getreportno = data =>
  axios.post("/abd/family/selectListsByFamilyno", data);

// 添加家庭成员
export const addfamilymember = data =>
  axios.post("/abd/family/addfamilymembers", data);

// 手动录入保单
export const addtxt = data =>
  axios.post("/abd/abtComAbdPolicyImg/addTXT", data);

// 家庭成员列表
export const showfamilymember = params =>
  axios.get("/abd/family/showfamily", params);

// detail 查看家庭成员报告
export const lookAnalysis = data => axios.post("/abd/muser/showCustomer", data);

// 生成家庭报告
export const createAnalysis = data =>
  axios.post("/abd/muser/getAnalysis", data);

// 保单信息回显
export const selectRequestmsg = data =>
  axios.post("/abd/abtComAbdPolicyImg/selectRequestmsg", data);
