<!--
 * @Descripttion:
 * @version:
 * @Author: 贾峰
 * @Date: 2019-11-22 20:07:18
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-05-27 18:03:11
 -->
<template>
  <div class="mainbody">
    <div class="diabg"
         v-if="showdialog"></div>
    <div class="diabg"
         v-if="showruledialog"></div>
    <div class="dialog"
         v-if="showdialog">
      <p>请在保险合同中找到保险单信息页</p>
      <div class="simple">
        <div class="simpleleft">
          <img src="@/assets/abd/image/SAAS-71.png"
               alt="" />
          <img :src="getStorage('ossurl','')+getStorage('imgs',{}).trueicon"
               alt="" />
        </div>
        <div class="simpleright">
          <img src="@/assets/abd/image/SAAS-72.png"
               alt="" />
          <img src="@/assets/abd/image/SAAS-73.png"
               alt="" />
          <img src="@/assets/abd/image/SAAS-74.png"
               alt="" />
        </div>
      </div>
      <p class="bottomp">请将保单平放于桌面拍照，并保持光线充足</p>
      <img src="@/assets/abd/image/closedialog.png"
           class="closedialog"
           @click="closedialog" />
    </div>
    <div class="uploadrule"
         v-if="showruledialog">
      <p class="ruletitle">上传规则</p>
      <div class="rulediv">
        <span class="rule1">
          1.每次可上传<span :style="'color:'+getStorage('theme','')">同一份合同中</span>的<span :style="'color:'+getStorage('theme','')">多张</span>保单照片，如
        </span>
        <span class="rule1">有<span :style="'color:'+getStorage('theme','')">保障计划列表页</span>须一并上传。</span>
        <p class="rule2">2.不属于同一份合同的保单图片，恕不解析！</p>
      </div>
      <p class="ruledetails">
        尊敬的用户，您上传的照片信息，平台会严格保障您的隐私，平台数据库已采取最高等级加密，保障您的信息不会泄露。请放心上传
      </p>
      <img src="@/assets/abd/image/closedialog.png"
           class="closeruledialog"
           @click="closedialog('rule')" />
    </div>
    <div class="titleimg"></div>
    <md-tabs @change="changeTab"
             ref="tab"
             v-model="current"
             @reflowTabBar="resizetabbar"
             immediate>
      <md-tab-pane class="upleft"
                   name="p1"
                   label="拍照上传">
        <div class="setexample">
          <span @click="opendialog">如何正确拍摄您的保单图片？</span>
          <p>
            <span @click="opendialog('rule')">上传规则</span>
            <md-icon name="info"
                     size="lg"></md-icon>
          </p>
        </div>
        <div class="camera">
          <div class="piclist"
               v-if="imageList.length === 1">
            <div class="picleft">
              <img :src="imageList[0]"
                   alt=""
                   @click="viewpic" />
              <img src="@/assets/abd/image/s-109.png"
                   alt=""
                   class="delandview del"
                   @click="delpionly" />
              <img src="@/assets/abd/image/s-110.png"
                   alt=""
                   class="delandview"
                   @click="viewpic" />
            </div>
            <div class="picright">
              <img :src="getStorage('ossurl','')+getStorage('imgs',{}).upload"
                   alt=""
                   style="cursor:pointer"
                   @touchend="uploadpic" />
            </div>
          </div>
          <div class="piclists"
               v-if="imageList.length > 1">
            <div class="imgtop"
                 v-for="(item, index) in imageList"
                 :key="index">
              <img :src="item"
                   alt=""
                   @click="viewpic(index)" />
              <img src="@/assets/abd/image/s-109.png"
                   alt=""
                   class="delandview del"
                   @click="delpicitem(index)" />
              <img src="@/assets/abd/image/s-110.png"
                   alt=""
                   class="delandview"
                   @click="viewpic(index)" />
            </div>
            <div class="imgtop"
                 v-if="imageList.length < 4">
              <img :src="getStorage('ossurl','')+getStorage('imgs',{}).upload"
                   alt=""
                   style="cursor:pointer"
                   @touchend="uploadpic"
                   class="upup" />
            </div>
          </div>
          <div class="uploaddiv"
               v-if="imageList.length === 0">
            <img :src="getStorage('ossurl','')+getStorage('imgs',{}).upload"
                 @touchend="uploadpic">
          </div>
          <input type="file"
                 accept="image/*"
                 @change="selectpic"
                 v-show="false"
                 ref="inputfile" />

        </div>
        <p class="inskeep">该信息只用于保单分析，我们承诺保护您的隐私</p>
        <md-button :type="submitimglist.length === 0 ? 'disabled' : 'primary'"
                   :style="'background:'+getStorage('theme','')"
                   :loading="submitstatus"
                   :inactive="submitstatus"
                   @click="submit">{{ imageList.length > 0 ? "确认并上传" : "提交" }}</md-button>
      </md-tab-pane>
      <md-tab-pane class="content"
                   name="p2"
                   label="手工录入"
                   v-if="personkey === '0'">
        <div class="type-in">
          <md-field>
            <p class="policydetail">保单详情</p>
            <!-- 主险 -->
            <md-field-item disabled
                           title="主险"
                           class="require tjmes" />
            <md-input-item title="保险公司："
                           placeholder="请输入保险公司名称"
                           :solid="false"
                           v-model="form.mainrisk.companyname"
                           align="right"></md-input-item>
            <md-input-item title="产品名称："
                           placeholder="请输入产品名称"
                           :solid="false"
                           v-model="form.mainrisk.classname"
                           align="right"></md-input-item>
            <md-input-item title="保障额度："
                           placeholder="请输入保额"
                           :solid="false"
                           v-model="form.mainrisk.amount"
                           align="right"></md-input-item>
            <md-input-item title="保费："
                           placeholder="请输入保费"
                           :solid="false"
                           v-model="form.mainrisk.mount"
                           align="right"></md-input-item>
            <md-field-item title="缴费方式："
                           :content="form.mainrisk.paycode"
                           @click="ispaycodeShow = true"
                           arrow
                           :solid="false">
            </md-field-item>

            <md-selector v-model="ispaycodeShow"
                         :data="paycodedata"
                         title="缴费方式"
                         default-value="1"
                         large-radius
                         @choose="onpaycodeChoose"></md-selector>
            <md-input-item title="缴费年限："
                           placeholder="请输入缴费年限"
                           :solid="false"
                           v-model="form.mainrisk.yearnum"
                           align="right"></md-input-item>
            <md-field-item title="生效时间："
                           :solid="false"
                           arrow="arrow-right"
                           align="right"
                           :content="form.mainrisk.paydate"
                           @click.native="ispayDatePickerShow = true">
            </md-field-item>
            <md-date-picker ref="paydate"
                            v-model="ispayDatePickerShow"
                            type="custom"
                            title="选择生效时间"
                            large-radius
                            :custom-types="['yyyy', 'MM', 'dd']"
                            @change="onpayDateChange"
                            @confirm="onpayDateConfirm"></md-date-picker>
            <md-field-item title="受益人类型："
                           :content="form.mainrisk.benetype"
                           @click="issyrtypeShow = true"
                           arrow
                           :solid="false">
            </md-field-item>

            <md-selector v-model="issyrtypeShow"
                         :data="syrtypedata"
                         title="受益人类型"
                         default-value="1"
                         large-radius
                         @choose="onsyrtypeChoose"></md-selector>

            <!-- 附加险 -->
            <div class="extrafold extrat"
                 @click="clickextra">
              <p>附加险</p>
              <img src="@/assets/abd/image/extraright1.png"
                   style="width:6vw;height:4vw;padding-left:2vw"
                   v-if="!showextra" />
              <img src="@/assets/abd/image/extratobottom.png"
                   style="width:5vw;height:6vw;"
                   v-if="showextra" />
            </div>
            <div v-for="(item, inx) in form.additionalrisks"
                 :key="inx"
                 v-show="showextra">
              <md-field-item disabled
                             :title="'附加险' + (inx + 1)"
                             class="tjmes additionalrisks">
                <img src="@/assets/abd/image/SAAS-64.png"
                     slot="right"
                     @click="additionalrisks" />
              </md-field-item>
              <md-input-item title="产品名称："
                             placeholder="请输入产品名称"
                             :solid="false"
                             v-model="item.classname"
                             align="right"></md-input-item>
              <md-input-item title="保障额度："
                             placeholder="请输入保额"
                             :solid="false"
                             v-model="item.amount"
                             align="right"></md-input-item>
              <md-input-item title="保费："
                             placeholder="请输入保费"
                             :solid="false"
                             v-model="item.mount"
                             align="right"></md-input-item>
              <md-field-item title="缴费方式："
                             :content="item.paycode"
                             @click="showextrapaycode(inx)"
                             arrow
                             :solid="false">
              </md-field-item>

              <md-selector v-model="extrapaycodeShow[inx]"
                           :data="paycodedata"
                           title="缴费方式"
                           default-value="1"
                           @show="showwhich(inx)"
                           large-radius
                           @choose="onextrapaycodeChoose"></md-selector>
              <md-field-item title="生效时间："
                             :solid="false"
                             arrow="arrow-right"
                             align="right"
                             :content="item.sxdate"
                             @click.native="extrasxDatePickerShow(inx)">
              </md-field-item>
              <md-date-picker ref="extrasxdate"
                              v-model="extrasxDateShow[inx]"
                              type="custom"
                              title="选择生效时间"
                              large-radius
                              :custom-types="['yyyy', 'MM', 'dd']"
                              @change="onfjsxDateChange(inx)"
                              @confirm="onfjsxDateConfirm(inx)"></md-date-picker>
              <div class="delextra"
                   @click="delextra(inx)"
                   v-if="form.additionalrisks.length !== 1">
                <p>删除</p>
              </div>
            </div>
            <div class="extrafold"
                 @click="showtb = !showtb">
              <p>投被保人信息</p>
              <img src="@/assets/abd/image/extraright1.png"
                   style="width:6vw;height:4vw;padding-left:2vw"
                   v-if="!showtb" />
              <img src="@/assets/abd/image/extratobottom.png"
                   style="width:5vw;height:6vw;"
                   v-if="showtb" />
            </div>
            <div class="tbbr"
                 v-show="showtb">
              <md-field-item disabled
                             title="投被保人信息"
                             class="require tjmes" />
              <md-input-item title="投保人："
                             :solid="false"
                             readonly
                             value="投保人"
                             align="right"></md-input-item>
              <md-input-item title="姓名："
                             placeholder="请输入姓名"
                             :solid="false"
                             v-model="form.person[0].pname"
                             align="right"></md-input-item>
              <md-field-item title="性别："
                             :content="form.person[0].psex"
                             @click="showtbsex = true"
                             arrow
                             :solid="false">
              </md-field-item>

              <md-selector v-model="showtbsex"
                           :data="psexselectdata"
                           title="性别"
                           default-value="1"
                           large-radius
                           @choose="onchoosetbsex"></md-selector>
              <md-field-item title="出生日期："
                             :solid="false"
                             arrow="arrow-right"
                             align="right"
                             :content="form.person[0].pbirthdate"
                             @click.native="pbirthdateShow = true">
              </md-field-item>
              <md-date-picker ref="pbirthdate"
                              v-model="pbirthdateShow"
                              type="custom"
                              title="选择出生日期"
                              :min-date="minDate"
                              large-radius
                              :custom-types="['yyyy', 'MM', 'dd']"
                              @confirm="pbirthdateConfirm"></md-date-picker>
              <md-field-item title="证件类型："
                             :content="form.person[0].cardtype"
                             @click="showpcard = true"
                             arrow
                             :solid="false">
              </md-field-item>

              <md-selector v-model="showpcard"
                           :data="pcardselectdata"
                           title="证件类型"
                           default-value="1"
                           large-radius
                           @choose="onchoosecardtype"></md-selector>
              <md-input-item title="证件号码："
                             placeholder="证件号码"
                             :solid="false"
                             name="cardnum"
                             v-model="form.person[0].cardno"
                             align="right"></md-input-item>
              <md-input-item title="被保人："
                             :solid="false"
                             value="被保人"
                             readonly
                             align="right"></md-input-item>
              <md-input-item title="姓名："
                             placeholder="请输入姓名"
                             :solid="false"
                             v-model="form.person[1].pname"
                             align="right"></md-input-item>
              <md-field-item title="性别："
                             :content="form.person[1].psex"
                             @click="showbbsex = true"
                             arrow
                             :solid="false">
              </md-field-item>

              <md-selector v-model="showbbsex"
                           :data="psexselectdata"
                           title="性别"
                           default-value="1"
                           large-radius
                           @choose="onchoosebbsex"></md-selector>
              <md-field-item title="出生日期："
                             :solid="false"
                             arrow="arrow-right"
                             align="right"
                             :content="form.person[1].pbirthdate"
                             @click.native="pbbirthdateShow = true">
              </md-field-item>
              <md-date-picker ref="pbbirthdate"
                              v-model="pbbirthdateShow"
                              type="custom"
                              title="选择出生日期"
                              :min-date="minDate"
                              large-radius
                              :custom-types="['yyyy', 'MM', 'dd']"
                              @confirm="pbbirthdateConfirm"></md-date-picker>
              <md-field-item title="证件类型："
                             :content="form.person[1].cardtype"
                             @click="showpbcard = true"
                             arrow
                             :solid="false">
              </md-field-item>

              <md-selector v-model="showpbcard"
                           :data="pcardselectdata"
                           title="证件类型"
                           default-value="1"
                           large-radius
                           @choose="onchoosebcardtype"></md-selector>
              <md-input-item title="证件号码："
                             placeholder="证件号码"
                             :solid="false"
                             name="cardnum"
                             v-model="form.person[1].cardno"
                             align="right"></md-input-item>
              <md-field-item title="投被受关系："
                             :content="form.person[1].relation"
                             @click="showpbrelation = true"
                             arrow
                             :solid="false">
              </md-field-item>
              <md-selector v-model="showpbrelation"
                           :data="pbrelationdata"
                           title="投被受关系"
                           default-value="1"
                           large-radius
                           @choose="onchoosebrelatione"></md-selector>
            </div>
            <div class="extrafold"
                 @click="showsyr = !showsyr"
                 v-if="showsyrtype">
              <p>受益人</p>
              <img src="@/assets/abd/image/extraright1.png"
                   style="width:6vw;height:4vw;padding-left:2vw"
                   v-if="!showsyr" />
              <img src="@/assets/abd/image/extratobottom.png"
                   style="width:5vw;height:6vw;"
                   v-if="showsyr" />
            </div>
            <div v-for="(item, index) in form.beneficiary"
                 :key="index + '0'"
                 v-show="showsyr">
              <md-field-item disabled
                             :title="'受益人' + (index + 1)"
                             class="tjmes">
                <img src="@/assets/abd/image/SAAS-64.png"
                     slot="right"
                     style="width:4vw;height:4vw"
                     @click="addsyr" /></md-field-item>
              <md-input-item title="受益人："
                             :solid="false"
                             value="受益人"
                             align="right"
                             readonly></md-input-item>
              <md-input-item title="姓名："
                             placeholder="请输入姓名"
                             :solid="false"
                             v-model="item.pname"
                             align="right"></md-input-item>
              <md-field-item title="性别："
                             :content="item.psex"
                             @click="showsysex(index)"
                             arrow
                             :solid="false">
              </md-field-item>

              <md-selector v-model="ishowsysex[index]"
                           :data="psexselectdata"
                           title="性别"
                           @show="showwhich(index)"
                           default-value="1"
                           large-radius
                           @choose="onchoosesysex"></md-selector>
              <md-field-item title="出生日期："
                             :solid="false"
                             arrow="arrow-right"
                             align="right"
                             :content="item.pbirthdate"
                             @click.native="sybirthdateShow(index)">
              </md-field-item>
              <md-date-picker ref="sybirthdate"
                              v-model="isybirthdateShow[index]"
                              type="custom"
                              title="选择出生日期"
                              :min-date="minDate"
                              large-radius
                              :custom-types="['yyyy', 'MM', 'dd']"
                              @confirm="isybirthdateConfirm(index)"></md-date-picker>
              <md-field-item title="证件类型："
                             :content="item.cardtype"
                             @click="sypcardtypeshow(index)"
                             arrow
                             :solid="false">
              </md-field-item>

              <md-selector v-model="isypcardtype[index]"
                           :data="pcardselectdata"
                           title="证件类型"
                           @show="showwhich(index)"
                           default-value="1"
                           large-radius
                           @choose="onsycardtype"></md-selector>
              <md-input-item title="证件号码："
                             placeholder="证件号码"
                             :solid="false"
                             name="cardnum"
                             v-model="item.cardno"
                             align="right"></md-input-item>
              <md-field-item title="投被受关系："
                             :content="item.relation"
                             @click="showsyrelation(index)"
                             arrow
                             :solid="false">
              </md-field-item>
              <md-selector v-model="ishowsyrelation[index]"
                           :data="syrelationdata"
                           title="投被受关系"
                           @show="showwhich(index)"
                           default-value="1"
                           large-radius
                           @choose="onchoosesyrelatione"></md-selector>
              <md-input-item title="受益比例："
                             placeholder="受益比例"
                             :solid="false"
                             name="syrate"
                             v-model="item.syrate"
                             align="right"></md-input-item>
              <md-input-item title="受益顺序："
                             placeholder="受益顺序"
                             :solid="false"
                             name="syorderno"
                             v-model="item.syorderno"
                             align="right"></md-input-item>
              <div class="delextra"
                   @click="delsyr(index)"
                   v-if="form.beneficiary.length !== 1">
                <p>删除</p>
              </div>
            </div>
            <div class="remarks">
              <md-field-item disabled
                             title="备注"
                             class="tjmes" />
              <textarea v-model="form.remark"
                        style="border:0;border-radius:5px;background-color:white;width: 100%;height: 60px;padding: 10px;resize: none;"
                        placeholder="请输入备注"
                        class="remark" />
              </div>
            <md-button
              type="primary"
              :style="'background:'+getStorage('theme','')"
              :loading="showsubmitentry"
              :inactive="showsubmitentry"
              class="entry_btn"
              @click="submitentry"
              >保存</md-button
            >
          </md-field>
        </div>
      </md-tab-pane>
    </md-tabs>
    <md-image-viewer
      v-model="viewImgSwitch"
      :list="viewImgList"
      :initial-index="viewerIndex"
    />
    <div :style="'background:'+getStorage('theme','')" :class="personkey==='1'?'navsetting1':'navsetting'">
      <div class="backbtn" @click="backtopage" :style="'height:'+(personkey==='1'?'100%':'50%')">
        <span class="iconfont iconback"></span>
      </div>
      <div class="backtohome" @click="backtohomepage" v-if="personkey === '0'">
        <span class="iconfont iconhome"></span>
      </div>
    </div>
    <!-- <div class="backbtn" @click="backtopage">back</div>
    <div class="backtohome" @click="backtohomepage" v-if="personkey === '0'">home</div> -->
  </div>
</template>
<script>
import { Toast } from 'mand-mobile'
import { addpolicypic, addtxt, selectRequestmsg } from '@/api/abd/bdtg'
import { getSelectList } from '@/api/agent/agent'
import { cloneDeep } from 'lodash'
import { getStorage } from "@/lib/util";
export default {
  data () {
    return {
      syrtypedata: [],
      syrtype: '',
      showsyrtype: false,
      issyrtypeShow: false,
      //
      viewImgSwitch: false,
      viewImgList: [],
      viewerIndex: 0,
      /* 弹出层 */
      showdialog: false,
      showruledialog: false,
      imageList: [],
      submitimglist: [],
      submitstatus: false,
      /* 录入相关 */
      isforever: '2',
      isextraforever: ['2'],
      ispayDatePickerShow: false,
      isfjsxDatePickerShow: false,
      issxDatePickerShow: false,
      ispaycodeShow: false,
      showclasstype: false,
      classtype: '',
      paycode: '',
      classdata: [],
      paycodedata: [],
      extrapaycode: [],
      extrapaycodeShow: [false],
      item: '',
      extrasxdate: [],
      extrasxDateShow: [false],
      begliabdate: '',
      endliabdate: '',
      begliabdateDateShow: false,
      endliabdateDateShow: false,
      isappfShow: false,
      appfdata: [],
      /* 附险 */
      showextra: false,
      extraappfShow: [false],
      extrabegliabdate: [],
      extraendliabdate: [],
      extrabegliabdateShow: [false],
      extraendliabdateShow: [false],
      form: {
        mainrisk: {
          companyname: '',
          classname: '',
          classtype: '主险',
          policyno: '',
          mount: '',
          amount: '',
          paycode: '',
          paydate: '',
          yearnum: '',
          sxdate: '',
          benetype: ''
        },
        additionalrisks: [
          {
            classname: '',
            amount: '',
            mount: '',
            paycode: '',
            yearnum: '',
            sxdate: ''
          }
        ],
        person: [
          {
            rytype: '投保人',
            pname: '',
            psex: '',
            pbirthdate: '',
            cardtype: '',
            cardno: '',
            relation: ''
          },
          {
            rytype: '被保人',
            pname: '',
            psex: '',
            pbirthdate: '',
            cardtype: '',
            cardno: '',
            relation: ''
          }
        ],
        beneficiary: [
          {
            rytype: '',
            pname: '',
            psex: '',
            pbirthdate: '',
            cardtype: '',
            cardno: '',
            relation: '',
            syrate: '',
            syorderno: ''
          }
        ],
        remark: ''
      },
      /* 投被保人信息 */
      pbirthdateShow: false,
      showpcard: false,
      pcardselectdata: [],
      minDate: new Date('1940/1/1'),
      pbbirthdateShow: false,
      showpbcard: false,
      // prelationdata: [],
      // showprelation: false,
      showpbrelation: false,
      pbrelationdata: [],
      /* 受益人 */
      sybirthdate: [],
      isybirthdateShow: [false],
      sypcardtype: [],
      isypcardtype: [false],
      syrelation: [],
      ishowsyrelation: [false],
      syrelationdata: [],
      showtbsex: false,
      showbbsex: false,
      sysex: [],
      psexselectdata: [],
      ishowsysex: [false],
      showsyr: false,
      showtb: false,
      showsubmitentry: false,
      showsremark: false,
      personkey: '',
      empuserid: '',
      batchno: '',
      empno: '',
      comid: '',
      ishxdata: false,
      current: 'p1'
    }
  },
  watch: {
    syrtype (newval) {
      if (newval === '法定') {
        this.showsyrtype = false
        this.showsyr = false
      } else {
        this.showsyrtype = true
      }
    }
  },
  created () {
    this.getMJ()
    this.personkey = this.$route.query.personkey
    this.empuserid = this.$route.query.empuserid
    this.batchno = this.$route.query.batchno
    this.empno = this.$route.query.empno
    this.comid = this.$route.query.comid
    if (this.batchno) {
      this.getdetail()
      this.ishxdata = true
    }
  },
  mounted () {
    this.$nextTick(() => {
      let temp = document.getElementsByClassName('md-tab-bar-ink')
      let tem = document.getElementsByClassName('md-tab-bar-item')
      let te = document.getElementsByClassName('is-active')
      Array.from(temp).forEach(ele => {
        ele.style.cssText += `background-color:${getStorage('theme', '')} !important`
      })
      Array.from(tem).forEach(ele => {
        ele.style.cssText += `color:${getStorage('theme', '')} !important`
      })
      Array.from(te).forEach(ele => {
        ele.style.cssText += `color:${getStorage('theme', '')} !important`
      })
      // temp.style.cssText = `background-color:${getStorage('theme','')} !important`
      // te.style.cssText = `color:${getStorage('theme','')} !important`
      // tem.style.cssText = `color:${getStorage('theme','')} !important`
    })
  },
  updated () {
    let temp = document.getElementsByClassName('md-tab-bar-ink')
    let tem = document.getElementsByClassName('md-tab-bar-item')
    let te = document.getElementsByClassName('is-active')
    Array.from(temp).forEach(ele => {
      ele.style.cssText += `background-color:${getStorage('theme', '')} !important`
    })
    Array.from(tem).forEach(ele => {
      ele.style.cssText += `color:${getStorage('theme', '')} !important`
    })
    Array.from(te).forEach(ele => {
      ele.style.cssText += `color:${getStorage('theme', '')} !important`
    })
  },
  methods: {
     getdetail () {
      let data = {
        empno: this.empno,
        comid: this.comid,
        batchno: this.batchno
      }
      selectRequestmsg(data).then((res) => {
        let Data = res.data.data
        if (Data.batchtype == 'IMG') {
          this.current = 'p1'
          this.imageList = Data.imgs
          this.imageList = Data.imgs.map(function (item) {
            return `data:image/jpeg;base64,${item.picurl}`
          })
          this.submitimglist = cloneDeep(Data.imgs.map(function (item) {
            return item.picurl
          }))
        } else {
          this.current = 'p2'
          this.form = JSON.parse(Data.txtmsg)
          this.showextra = true
          this.showtb = true
          this.showsyr = true
        }
      })
    },
    onReaderSelect (name, { files }) {
      Toast.loading('图片读取中...')
    },
    onReaderComplete (name, { dataUrl, file }) {
      Toast.hide()
      this.imageList.push(dataUrl)
      var reader = new FileReader()
      reader.readAsDataURL(file)
      let that = this
      reader.onload = function () {
        let base64img = reader.result
          .toString()
          .replace(/^data:image\/\w+;base64,/, '')
        that.submitimglist.push(base64img)
      }
    },
    onReaderError (name, { msg }) {
      Toast.failed(msg)
    },
    onDeleteImage (index) {
      this.imageList.splice(index, 1)
    },
    onsyrtypeChoose ({ text }) {
      this.syrtype = text
      this.form.mainrisk.benetype = text
    },
    submit () {
      this.submitstatus = true
      addpolicypic({
        file: this.submitimglist,
        prefix: 'policy',
        familyno: this.$route.query.familyno,
        empuserid: this.empuserid
      })
        .then(res => {
          Toast.succeed('上传成功')
          this.imageList = []
          this.submitimglist = []
        })
        .finally(() => {
          this.submitstatus = false
        })
    },
    onclasstypeChoose ({ value, text }) {
      this.classtype = text
      this.mainrisk.classtype = value
    },
    onpayDateChange () { },
    onpayDateConfirm () {
      this.form.mainrisk.paydate = this.$refs.paydate.getFormatDate(
        'yyyy-MM-dd'
      )
    },
    onsxDateChange () { },
    onsxDateConfirm () {
      this.form.mainrisk.sxdate = this.$refs.sxdate.getFormatDate('yyyy-MM-dd')
    },
    extrasxDatePickerShow (index) {
      this.extrasxDateShow[index] = true
      this.$forceUpdate()
    },
    onfjsxDateChange () { },
    onfjsxDateConfirm (index) {
      this.form.additionalrisks[index].sxdate = this.$refs['extrasxdate'][
        index
      ].getFormatDate('yyyy-MM-dd')
      this.extrasxdate[index] = this.form.additionalrisks[index].sxdate
      this.$forceUpdate()
    },
    onpaycodeChoose ({ value, text }) {
      this.form.mainrisk.paycode = text
    },
    showwhich (item) {
      this.item = item
    },
    showextrapaycode (index) {
      this.extrapaycodeShow[index] = true
      this.$forceUpdate()
    },
    onextrapaycodeChoose ({ value, text }) {
      this.form.additionalrisks[this.item].paycode = text
    },
    begliabdateConfirm () {
      this.begliabdate = this.$refs.begliabdate.getFormatDate('yyyy-MM-dd')
      this.form.mainrisk.liabdate = this.begliabdate + '-' + this.endliabdate
    },
    endliabdateConfirm () {
      this.endliabdate = this.$refs.endliabdate.getFormatDate('yyyy-MM-dd')
      this.form.mainrisk.liabdate = this.begliabdate + '-' + this.endliabdate
    },
    onappfChoose ({ text, value }) {
      this.form.mainrisk.appf = text
    },
    extrabegliabdateDateShow (index) {
      this.extrabegliabdateShow[index] = true
      this.$forceUpdate()
    },
    extrabegliabdateConfirm (index) {
      this.extrabegliabdate[index] = this.$refs['extrabegliabdate'][
        index
      ].getFormatDate('yyyy-MM-dd')
      this.$forceUpdate()
      this.form.additionalrisks[index].liabdate =
        this.extrabegliabdate[index] + '-' + this.extraendliabdate[index]
    },
    extraendliabdateDateShow (index) {
      this.extraendliabdateShow[index] = true
      this.$forceUpdate()
    },
    extraendliabdateConfirm (index) {
      this.extraendliabdate[index] = this.$refs['extraendliabdate'][
        index
      ].getFormatDate('yyyy-MM-dd')
      this.$forceUpdate()
      this.form.additionalrisks[index].liabdate =
        this.extrabegliabdate[index] + '-' + this.extraendliabdate[index]
    },
    appfShow (index) {
      this.extraappfShow[index] = true
      this.$forceUpdate()
    },
    extraappfchoose ({ text }) {
      this.form.additionalrisks[this.item].appf = text
    },
    clickextra () {
      this.showextra = !this.showextra
    },
    additionalrisks () {
      this.form.additionalrisks.push({
        classname: '',
        amount: '',
        mount: '',
        paycode: '',
        yearnum: '',
        liabdate: '',
        sxdate: '',
        appf: ''
      })
    },
    delextra (index) {
      this.form.additionalrisks.splice(index, 1)
    },
    pbirthdateConfirm () {
      this.form.person[0].pbirthdate = this.$refs.pbirthdate.getFormatDate(
        'yyyy-MM-dd'
      )
    },
    pbbirthdateConfirm () {
      this.form.person[1].pbirthdate = this.$refs.pbbirthdate.getFormatDate(
        'yyyy-MM-dd'
      )
    },
    onchoosecardtype ({ text, value }) {
      this.form.person[0].cardtype = text
    },
    onchoosebcardtype ({ text }) {
      this.form.person[1].cardtype = text
    },
    // onchooserelatione ({ text }) {
    //   this.form.person[0].relation = text
    // },
    onchoosebrelatione ({ text }) {
      this.form.person[1].relation = text
    },
    /* 受益人 */
    sybirthdateShow (index) {
      this.isybirthdateShow[index] = true
      this.$forceUpdate()
    },
    isybirthdateConfirm (index) {
      this.sybirthdate[index] = this.$refs['sybirthdate'][index].getFormatDate(
        'yyyy-MM-dd'
      )
      this.form.beneficiary[index].pbirthdate = this.sybirthdate[index]
      this.$forceUpdate()
    },
    sypcardtypeshow (index) {
      this.isypcardtype[index] = true
      this.$forceUpdate()
    },
    onsycardtype ({ text }) {
      this.form.beneficiary[this.item].cardtype = text
      this.sypcardtype[this.item] = text
    },
    showsyrelation (index) {
      this.ishowsyrelation[index] = true
      this.$forceUpdate()
    },
    onchoosesyrelatione ({ text }) {
      this.form.beneficiary[this.item].relation = text
      this.syrelation[this.item] = text
    },
    /* 性别 */
    onchoosetbsex ({ text }) {
      this.form.person[0].psex = text
    },
    onchoosebbsex ({ text }) {
      this.form.person[1].psex = text
    },
    showsysex (index) {
      this.ishowsysex[index] = true
      this.$forceUpdate()
    },
    onchoosesysex ({ text }) {
      this.form.beneficiary[this.item].psex = text
      // this.sysex[this.item] = text
    },
    addsyr () {
      this.form.beneficiary.push({
        rytype: '',
        pname: '',
        psex: '',
        pbirthdate: '',
        cardtype: '',
        cardno: '',
        relation: '',
        syrate: '',
        syorderno: ''
      })
    },
    delsyr (index) {
      this.form.beneficiary.splice(index, 1)
    },
    submitentry () {
      // console.log(this.form)
      if (
        this.form.mainrisk.companyname === '' ||
        this.form.mainrisk.classname === ''
      ) {
        Toast.failed('请填写主险信息')
      } else {
        this.showsubmitentry = true
        addtxt({
          familyno: this.$route.query.familyno,
          jsonObject: JSON.stringify(this.form)
        })
          .then(res => {
            Toast.succeed('上传成功')
            this.form = {
              mainrisk: {
                companyname: '',
                classname: '',
                classtype: '主险',
                policyno: '',
                mount: '',
                amount: '',
                paycode: '',
                paydate: '',
                yearnum: '',
                sxdate: '',
                benetype: ''
              },
              additionalrisks: [
                {
                  classname: '',
                  amount: '',
                  mount: '',
                  paycode: '',
                  yearnum: '',
                  sxdate: ''
                }
              ],
              person: [
                {
                  rytype: '投保人',
                  pname: '',
                  psex: '',
                  pbirthdate: '',
                  cardtype: '',
                  cardno: '',
                  relation: ''
                },
                {
                  rytype: '被保人',
                  pname: '',
                  psex: '',
                  pbirthdate: '',
                  cardtype: '',
                  cardno: '',
                  relation: ''
                }
              ],
              beneficiary: [
                {
                  rytype: '',
                  pname: '',
                  psex: '',
                  pbirthdate: '',
                  cardtype: '',
                  cardno: '',
                  relation: '',
                  syrate: '',
                  syorderno: ''
                }
              ],
              remark: ''
            }
          })
          .finally(() => {
            this.showsubmitentry = false
          })
      }
    },
    /* 切换tab */
    changeTab (tab) {
      this.$nextTick(() => {
        let temp = document.getElementsByClassName('md-tab-bar-ink')
        let tem = document.getElementsByClassName('md-tab-bar-item')
        let te = document.getElementsByClassName('is-active')
        Array.from(temp).forEach(ele => {
          ele.style.cssText += `background-color:${getStorage('theme', '')} !important`
        })
        Array.from(tem).forEach(ele => {
          ele.style.cssText += `color:${getStorage('theme', '')} !important`
        })
        Array.from(te).forEach(ele => {
          ele.style.cssText += `color:${getStorage('theme', '')} !important`
        })
      })
    },
    getMJ () {
      if (this.paycodedata.length === 0) {
        getSelectList({
          list: [
            'SETCODE',
            'PLANTTYPE',
            'BBCARD',
            'TBSHIP',
            'BSSHIP',
            'SEX',
            'SYRTYPE'
          ]
        }).then(res => {
          res.data.data.forEach(item => {
            switch (item.label) {
              case 'SETCODE':
                this.paycodedata = item.value
                this.paycodedata.forEach(item => {
                  item.value = item.code
                  item.text = item.name
                })
                break
              case 'PLANTTYPE':
                this.appfdata = item.value
                this.appfdata.forEach(item => {
                  item.value = item.code
                  item.text = item.name
                })
                break
              case 'BBCARD':
                this.pcardselectdata = item.value
                this.pcardselectdata.forEach(item => {
                  item.value = item.code
                  item.text = item.name
                })
                break
              case 'TBSHIP':
                this.pbrelationdata = item.value
                this.pbrelationdata.forEach(item => {
                  item.value = item.code
                  item.text = item.name
                })
                break
              case 'BSSHIP':
                this.syrelationdata = item.value
                this.syrelationdata.forEach(item => {
                  item.value = item.code
                  item.text = item.name
                })
                break
              case 'SEX':
                this.psexselectdata = item.value
                this.psexselectdata.forEach(item => {
                  item.value = item.code
                  item.text = item.name
                })
                break
              case 'SYRTYPE':
                this.syrtypedata = item.value
                this.syrtypedata.forEach(item => {
                  item.value = item.code
                  item.text = item.name
                })
                break
              default:
                break
            }
          })
        })
      }
     
    },
    closedialog (params) {
      if (params === 'rule') {
        this.showruledialog = false
      } else {
        this.showdialog = false
      }
    },
    opendialog (params) {
      if (params === 'rule') {
        this.showruledialog = true
      } else {
        this.showdialog = true
      }
    },
    /* 上传图片 */
    uploadpic () {
      this.$refs.inputfile.click()
      // document.getElementById('inputfile').click()
    },
    selectpic (e) {
      let file = e.target.files[0]
      console.log(file)
      /* if (file && !file.type.match('image.*')) {
        Toast.failed('只支持图片上传')
        return false
      }
      if (file && file.type.match('gif')) {
        Toast.failed('暂不支持gif格式图片')
        this.$refs.inputfile.value = null
        return false
      } */
      let reader = new FileReader()
      console.log(reader)
      reader.readAsDataURL(file)
      let that = this
      reader.onload = function (params) {
        that.imageList.push(reader.result)
        let base64img = reader.result
          .toString()
          .replace(/^data:image\/\w+;base64,/, '')
        that.submitimglist.push(base64img)
      }
    },
    delpionly () {
      this.imageList = []
      this.submitimglist = []
      this.$refs.inputfile.value = null
    },
    viewpic (index) {
      if (this.imageList.length > 1) {
        this.viewerIndex = index
        this.viewImgList = this.imageList
        this.viewImgSwitch = true
      } else {
        this.viewImgList = this.imageList
        this.viewImgSwitch = true
      }
    },
    delpicitem (index) {
      this.imageList.splice(index, 1)
      this.submitimglist.splice(index, 1)
    },
    resizetabbar () {
      console.log('ok')
    },
    backtopage () { this.$router.go(-1) },
    backtohomepage () { this.$router.push({ path: '/bdtg', query: { fromwhere: this.$route.query.fromwhere, bdtgflush: true } }) }
  }
}
</script>
<style lang="stylus" scoped>
.mainbody {
  background-color: #F7F6FB;
  width: 100vw;
  height: 400vh;
}

.upleft {
  background-color: white;

  .setexample {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(133, 136, 140, 1);
    padding: 20px 5vw;
    margin-top: 20px;
    background-color: white;

    span {
      letter-spacing: 3px;
      text-decoration: underline;
      font-family: PingFang SC;
      margin-right: 10px;
      font-size: 32px;
    }
  }

  .inskeep {
    margin: 0 5vw;
    margin-top: 40px;
    color: rgba(133, 136, 140, 1);
    font-family: PingFang SC;
    letter-spacing: 3px;
    font-size: 30px;
  }

  /deep/ .md-button {
    background-color: color-primary;
    height: 100px;
    border-radius: 8px;
    width: 90vw;
    margin: 0 5vw;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
}

.titleimg {
  height: 24vh;
  background: url('~@/assets/abd/image/SAAS-69.png') no-repeat;
  background-size: 100% 100%;
}

/deep/ .md-tab-bar {
  padding: 0;
}

// /deep/ .md-tab-bar-ink {
// background-color: color-primary;
// padding 0
// width 50vw
// }
// /deep/ .md-tab-bar-item.is-active {
// color: color-primary;
// }
// /deep/ .md-tab-pane content{
// background-color  #F7F6FB
// }
.camera {
  position: relative;
  z-index: 99;
  height: 50vh;

  .uploaddiv {
    display: flex;
    justify-content: center;

    img {
      width: 44vw;
      margin: 4vw 0;
      cursor: pointer;
    }
  }
}

/* 图片上传选择 */
.add {
  padding-bottom: 0 !important;
}

/* 手工录入 */
.type-in {
  background-color: white;

  .policydetail {
    font-size: 37px;
    font-weight: bold;
  }
}

/deep/ .md-field-item-title {
  font-size: 35px;
}

/deep/ .md-field-item-control {
  display: flex;
  justify-content: flex-end;
}

/deep/ .require {
  .md-field-item-title::after {
    content: '*';
    color: red;
  }
}

/deep/ .tjmes {
  .md-field-item-title {
    font-size: 35px;
    padding-left: 0;
    font-weight: bold;
  }
}

/deep/ .additionalrisks {
  .md-field-item-right {
    img {
      width: 4vw;
      height: 4vw;
    }
  }
}

/* 附加险折叠 */
.extrafold {
  display: flex;
  height: 8vh;
  border-top: 10px solid #F7F6FB;
  border-bottom: 10px solid #F7F6FB;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 36px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: bold;
    color: black;
  }

  img {
    width: 3vw;
    height: 4vw;
  }
}

.extrat {
  border-top: 20px solid #F7F6FB;
}

.sy {
  border-bottom: 20px solid #F7F6FB;
}

.delextra {
  display: flex;
  justify-content: center;
  color: #FF9F7A;
  cursor: pointer;
  padding: 15px 0;

  p {
    color: #FF9F7A;
    font-size: 30px;
    padding: 4px 16px;
    border-radius: 8px;
    border: 1px dashed #FF9F7A;
  }
}

.entry_btn {
  background-color: color-primary;
  height: 6vh;
  border-radius: 8px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.diabg {
  position: absolute;
  z-index: 111;
  background-color: black;
  opacity: 0.55;
  width: 100vw;
  height: 100vh;
}

/* 返回按钮 */
.navsetting {
  width: 10vw;
  height: 20vw;
  border-radius: 10px;
  background-color: color-primary;
  position: fixed;
  right: 10px;
  z-index: 999;
  bottom: 40%;
}

.navsetting1 {
  width: 10vw;
  height: 10vw;
  border-radius: 10px;
  background-color: color-primary;
  position: fixed;
  right: 10px;
  z-index: 999;
  bottom: 40%;
}

.backbtn {
  width: 80%;
  height: 50%;
  margin: 0 auto;
  border-bottom: 0.8px solid rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 50px;
  }
}

.backtohome {
  width: 80%;
  height: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    font-size: 50px;
  }
}

.uploadrule {
  position: fixed;
  width: 87vw;
  height: 45vh;
  z-index: 120;
  border-radius: 15px;
  background-color: white;
  top: 17vh;
  left: 6.5vw;

  .ruletitle {
    font-family: PingFang SC;
    color: rgba(56, 56, 56, 1);
    font-size: 46px;
    font-weight: 560;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
  }

  .rulediv {
    height: 22vh;
  }

  .rule1 {
    font-family: PingFang-SC-Medium;
    padding: 0 2vw;
    font-size: 38px;

    span {
      color: color-primary;
      font-size: 38px;
    }
  }

  .rule2 {
    font-family: PingFang-SC-Medium;
    padding: 1vh 2vw;
    font-size: 38px;
  }

  .ruledetails {
    height: 13vh;
    color: #757575;
    font-family: PingFang-SC-Medium;
    padding: 0 5%;
    font-size: 32px;
  }
}

.dialog {
  position: fixed;
  padding: 4vw 0;
  width: 87vw;
  height: 62.6vh;
  z-index: 120;
  border-radius: 12px;
  background-color: white;
  top: 15vh;
  left: 6.5vw;

  p {
    display: flex;
    justify-content: center;
    font-size: 36px;
    font-weight: bold;
    color: #4B4B4B;
    padding: 2vh 0 4vh;
    letter-spacing: 2px;
  }

  .simple {
    display: flex;
    margin: 0 8vw;
    height: 40vh;

    .simpleleft {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;

      img:nth-child(2) {
        width: 4vw;
        height: 4vw;
        margin-top: 2vw;
      }
    }

    .simpleright {
      width: 40%;
      display: flex;
      flex-direction: column;

      img {
        width: 80%;
        height: 16vw;
        margin: 3vw 6vw;
      }
    }
  }

  .bottomp {
    color: #B9B9B9;
    font-size: 30px;
    margin-top: 40px;
    // padding-bottom 10px
  }
}

/deep/ .quarter-bg {
  background: #FD5A55 !important;
}

.closedialog {
  position: fixed;
  z-index: 121;
  bottom: 13vh;
  width: 8vw;
  height: 8vw;
  left: 46vw;
}

.closeruledialog {
  position: fixed;
  z-index: 121;
  bottom: 26vh;
  width: 8vw;
  height: 8vw;
  left: 46vw;
}

.piclist {
  height: 82%;
  display: flex;
  justify-content: space-between;
  margin: 0 4vw;

  .picleft {
    position: relative;
    width: 45vw;
    background-color: white;

    .delandview {
      position: absolute;
      bottom: 2vw;
      right: 4vw;
      width: 6vw;
      height: 6vw;
    }

    .del {
      right: 15vw;
    }
  }

  .picright {
    width: 45vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 44vw;
      height: 59.4vw;
      background-color: white;
    }
  }
}

.piclists {
  height: 82%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 4vw;

  .imgtop {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 45%;
    margin: 0 20px;
    background-color: white;

    .delandview {
      position: absolute;
      bottom: 2vw;
      right: 4vw;
      width: 6vw;
      height: 6vw;
    }

    .del {
      right: 12vw;
    }
  }

  .upup {
    height: 31.89vw;
    width: 23.62vw;
  }
}

.remark::-webkit-input-placeholder { /* WebKit browsers */
  color: #ced0d6;
}

/deep/ .md-tab-bar-list {
  .md-tab-bar-item {
    font-size: 35px;
  }
}

.image-reader-item {
  position: relative;
  float: left;
  width: 23vw;
  padding-bottom: 23.5%;
  margin-bottom: 2%;
  margin-right: 2%;
  background: #FFF;
  box-shadow: 0 5px 20px rgba(197, 202, 213, 0.25);
  box-sizing: border-box;
  list-style: none;
  border-radius: 4px;
  background-size: cover;
  overflow: hidden;
  z-index: 99;

  &:nth-of-type(4n) {
    margin-right: 0;
  }
}

/* &.add
.md-icon
  position absolute
  top 40%
  left 50%
  transform translate(-50%, -50%)
  opacity .5
p
  position absolute
  top 50%
  left 0
  width 100%
  margin-top 15px
  font-size 22px
  color #CCC
  text-align center */
.image-reader-item-del {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  opacity: 0.8;

  .md-icon-close {
    font-size: 24px;
  }
}
</style>
